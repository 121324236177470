// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-404-js": () => import("./../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-feedback-js": () => import("./../src/templates/feedback.js" /* webpackChunkName: "component---src-templates-feedback-js" */),
  "component---src-templates-guide-js": () => import("./../src/templates/guide.js" /* webpackChunkName: "component---src-templates-guide-js" */),
  "component---src-templates-landing-js": () => import("./../src/templates/landing.js" /* webpackChunkName: "component---src-templates-landing-js" */),
  "component---src-templates-misc-js": () => import("./../src/templates/misc.js" /* webpackChunkName: "component---src-templates-misc-js" */),
  "component---src-templates-single-page-manual-js": () => import("./../src/templates/single-page-manual.js" /* webpackChunkName: "component---src-templates-single-page-manual-js" */)
}

